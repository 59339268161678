class ExpandableMenu {
    static version = '1.1'
    activeClass = 'active'

    constructor (outerContainer, menuContainer) {
        this.menuContainer = menuContainer
        this.trigger = outerContainer.querySelector('[data-toggle="collapse"]')
        this.collapsiblePanel = outerContainer.querySelector(this.trigger.dataset.target)
        this.triggerPlaceholder = this.trigger.dataset.placeholder

        this.setPlaceholder()

        jQuery(this.collapsiblePanel).on('show.bs.collapse', this.onShow.bind(this))
        jQuery(this.collapsiblePanel).on('hide.bs.collapse', this.onHide.bind(this))
    }

    onShow () { this.trigger.textContent = this.triggerPlaceholder }

    onHide () { this.setPlaceholder() }

    /**
     * @param {HTMLAnchorElement} menuLink
     */
    selectItem (menuLink) {
        this.unselect()
        menuLink.parentElement.classList.add(this.activeClass)
    }

    unselect () {
        const selectedItem = this.menuContainer.getElementsByClassName(this.activeClass)[0]
        selectedItem?.classList.remove(this.activeClass)
    }

    // Display current selected menu or the placeholder is none is selected
    setPlaceholder () {
        const selectedLink = this.menuContainer.querySelector('.' + this.activeClass)
        this.trigger.textContent = selectedLink?.textContent || this.triggerPlaceholder
    }

    reset () {
        this.unselect()
        this.setPlaceholder()
    }

    collapse () { jQuery(this.collapsiblePanel).collapse('hide') }
}



/**
 * Handles mobile side navigation.
 *
 * We're using Bootstrap Collapsible component with side menus that transform them into select-looking expandable lists
 * on mobile screens.
 */
(() => {
    const desktopMediaQuery = window.matchMedia('(min-width: 992px)')
    const sideNavContainers = document.getElementsByClassName('side-nav-container')

    for (const sideNavContainer of sideNavContainers) {
        const menuContainer = sideNavContainer.querySelector('.categories-list')
        const trigger = sideNavContainer.querySelector('[data-toggle="collapse"]')
        const collapsiblePanel = document.querySelector(trigger.dataset.target)

        new ExpandableMenu(sideNavContainer, menuContainer)

        if (desktopMediaQuery.matches) {
            trigger.style.setProperty('display', 'none')
            collapsiblePanel.classList.add('show')
            // collapsiblePanel.firstElementChild.classList.remove('mt-3')
        }
    }
})();

let topMenuContainer = document.getElementById('top-menu-container');


$(topMenuContainer).on('show.bs.collapse', function(event) {
    // document.body.classList.add('modal-open');
    if (event.target !== topMenuContainer) return;

    document.body.classList.add('top-menu-opened');
    $('#dummyModal').modal('show');
});
$(topMenuContainer).on('hidden.bs.collapse', function(event) {
    // document.body.classList.remove('modal-open');

    if (event.target !== topMenuContainer) return;
    document.body.classList.remove('top-menu-opened');
    $('#dummyModal').modal('hide');
});


/**
 * Closes the top menu dropdown by clicking on the backdrop.
 */
$(topMenuContainer).on('click', function(event) {
    if (event.target.nodeName.toLowerCase() !== 'a') {
        $(this).collapse('hide');
    }
});


if (typeof Swiper !== 'undefined') {
    new Swiper('.logos-slider', {
        slidesPerView: 'auto',
        loop: true,
        loopedSlides: 22,
        centeredSlides: true,
        freeMode: true,
        freeModeSticky: true,
        speed: 1500,
        autoplay: {
            delay: 2000,
            disableOnInteraction: true,
        }
    });

    new Swiper('#kudos-slider', {
        slidesPerView: 'auto',
        loop: true,
        // loopedSlides: 22,

        centeredSlides: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

/*    const teamGridHeader = document.getElementById('js-scroll-anchor-team');
    new Swiper('#team-slider', {
        autoHeight: true,
        navigation: {
            prevEl: '.swiper-button-first',
            nextEl: '.swiper-button-last',
        },
        on: {
            slideChangeTransitionStart: function () {
                teamGridHeader.scrollIntoView({behavior: 'smooth'})
            }
        }
    });*/
}


/**
 * Magnific Popup gallery.
 * @see http://dimsemenov.com/plugins/magnific-popup/documentation.html#gallery
 *
 * Adds popup functionality to galleries, created by Gutenberg (new WordPress editor).
 */
$('.wp-block-gallery').each(function() {
    $(this).magnificPopup({
        delegate: '.blocks-gallery-item a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile mfp-with-zoom',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
        },
        zoom: {
            enabled: true,
            duration: 200, // Change the duration in CSS as well
            opener: function(element) {
                return element.is('img') ? element : element.find('img');
            }
        }
    });
});

$('.js-team-person-popup').magnificPopup({
    type:'inline',
    mainClass: 'mfp-team-person-popup mfp-with-zoom',
    midClick: true,
    gallery: {
        enabled: true
    },
});
